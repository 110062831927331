<template>
  <b-modal
    id="compose-mail"
    :visible="shallShowEmailComposeModal"
    title="Create New Ticket"
    modal-class="modal-sticky"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="lg"
    no-fade
    hide-backdrop
    static
    @change="(val) => $emit('update:shall-show-ticket-compose-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        Create New Ticket
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="$emit('update:shall-show-ticket-compose-modal', false)"
        />
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="discardEmail"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          split
          variant="primary"
          size="sm"
          right
          @click="sendEmail"
        >
          Save
        </b-button>
        <b-button
          class="ml-1"
          variant="flat-info"
          @click.prevent="viewAttach"
        >
          <feather-icon
            icon="PaperclipIcon"
            size="14"
            class="cursor-pointer"
          />
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard Compose -->
        <feather-icon
          icon="TrashIcon"
          size="14"
          class="ml-75 cursor-pointer"
          @click="discardEmail"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <b-form>

      <!-- Field: To -->
      <div class="compose-mail-form-field">
        <label
          for="ticket-name"
          class="form-label"
        >Name: </label>
        <b-form-input
          id="ticket-name"
          v-model="composeData.name"
        />
      </div>

      <!-- Field: Cc (Hidden Initially) -->
      <div
        class="compose-mail-form-field"
      >
        <label
          for="ticket-email"
          class="form-label"
        >Email: </label>
        <b-form-input
          id="ticket-email"
          v-model="composeData.email"
        />
      </div>

      <div
        class="compose-mail-form-field"
      >
        <label
          for="ticket-phonenumber"
          class="form-label"
        >Phone: </label>
        <b-form-input
          id="ticket-phonenumber"
          v-model="composeData.phonenumber"
        />
      </div>

      <div
        class="compose-mail-form-field"
      >
        <label
          for="ticket-company"
          class="form-label"
        >Company: </label>
        <b-form-input
          id="ticket-company"
          v-model="composeData.company_name"
        />
      </div>

      <div
        class="compose-mail-form-field"
      >
        <label
          for="ticket-channel"
          class="form-label"
        >Channel: </label>
        <v-select
          id="ticket-channel"
          v-model="composeData.channel_id"
          :options="reference.channeledit"
          class="flex-grow-1 ticket-to-selector"
          :reduce="val => val.channel_id"
          :selectable="(option) => !option.name.includes('Email')"
          label="name"
        />
      </div>

      <div
        class="compose-mail-form-field"
      >
        <label
          for="ticket-priority"
          class="form-label"
        >Priority: </label>
        <v-select
          id="ticket-priority"
          v-model="composeData.priority_id"
          :options="reference.priority"
          class="flex-grow-1 ticket-to-selector"
          :reduce="val => val.priority_id"
          label="name"
        />
      </div>

      <div
        class="compose-mail-form-field"
      >
        <label
          for="ticket-status"
          class="form-label"
        >Status: </label>
        <v-select
          id="ticket-status"
          v-model="composeData.status_id"
          :options="ticketstatus"
          class="flex-grow-1 ticket-to-selector"
          :reduce="val => val.status_id"
          label="name"
        />
      </div>

      <!-- <div
        class="compose-mail-form-field"
      >
        <label
          for="ticket-zoom"
          class="form-label"
        >ZoomID: </label>
        <b-form-input
          id="ticket-zoom"
          v-model="composeData.zoom_id"
        />
      </div> -->

      <!-- Field: Subject -->
      <div class="compose-mail-form-field">
        <label
          for="ticket-subject"
          class="form-label"
        >Subject: </label>
        <b-form-input
          id="ticket-subject"
          v-model="composeData.subject"
        />
      </div>

      <!-- Field: Message - Quill Editor -->
      <div class="message-editor">
        <quill-editor
          id="quil-content"
          v-model="composeData.message"
          :options="editorOption"
        />
        <div
          id="quill-toolbar"
        >
          <!-- Add a bold button -->
          <button class="ql-bold" />
          <button class="ql-italic" />
          <button class="ql-underline" />
          <button class="ql-align" />
          <button class="ql-link" />
        </div>
      </div>

      <!-- Field: Response - Quill Editor -->
      <div
        v-if="composeData.status_id === 5 || composeData.status_id === 2"
        class="message-editor"
      >
        <quill-editor
          id="quil-content2"
          v-model="composeData.response"
          :options="editorOption2"
        />
        <div
          id="quill-toolbar2"
        >
          <!-- Add a bold button -->
          <button class="ql-bold" />
          <button class="ql-italic" />
          <button class="ql-underline" />
          <button class="ql-align" />
          <button class="ql-link" />
        </div>
      </div>

      <div
        v-if="attach"
        class="p-1"
      >
        <b-form-file
          v-model="files"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          size="sm"
          multiple
          @input="checkFile"
        />
      </div>

      <template v-if="alert">
        <b-alert
          :variant="alertDetail.variant"
          class="mx-1"
          dismissible
          show
        >
          <h4 class="alert-heading">
            {{ alertDetail.title }}
          </h4>
          <div
            v-if="alertDetail.variant === 'danger' && alertDetail.errors !== ''"
            class="alert-body"
          >
            <ul>
              <li
                v-for="(item, index) in alertDetail.errors"
                :key="index"
              >
                {{ `${index}: ${item}` }}
              </li>
            </ul>
          </div>
          <div
            v-else
            class="alert-body"
          >
            {{ alertDetail.text }}
          </div>
        </b-alert>
      </template>

    </b-form>

  </b-modal>
</template>

<script>
import {
  BForm, BFormInput, BButton, BAlert, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormFile,
    BAlert,

    // 3rd Party
    quillEditor,
    vSelect,
  },
  model: {
    prop: 'shallShowEmailComposeModal',
    event: 'update:shall-show-ticket-compose-modal',
  },
  props: {
    reference: {
      type: Object,
      required: true,
    },
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, { emit }) {
    const toast = useToast()
    const composeData = ref({})
    const showCcField = ref(false)
    const showBccField = ref(false)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Customer Detailed Problems',
    }

    const editorOption2 = {
      modules: {
        toolbar: '#quill-toolbar2',
      },
      placeholder: 'Agent Responses',
    }
    const discardEmail = () => {
      composeData.value = {}
      emit('update:shall-show-ticket-compose-modal', false)
      emit('new-ticket', true)
    }

    return {
      toast,
      composeData,
      editorOption,
      editorOption2,
      showCcField,
      showBccField,

      // Email actions
      discardEmail,
    }
  },
  data() {
    return {
      ticketData: '',
      ticketstatus: [
        { status_id: 2, name: 'Open Ticket' },
        { status_id: 5, name: 'Closed' },
        { status_id: 6, name: 'Spam' },
      ],
      files: [],
      attach: false,
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
    }
  },
  methods: {
    viewAttach() {
      this.attach = (this.attach === false)
    },
    checkFile(files) {
      this.alert = false
      files.map((val, key) => {
        if (val.type !== 'image/jpeg' && val.type !== 'image/png' && val.type !== 'application/pdf') {
          this.files.splice(key, 1)
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            errors: { file: `"${val.name}" extension invalid. only jpeg, png, and pdf allowed` },
          }
        }
        return true
      })
    },
    sendEmail() {
      document.getElementById('loading-bg').style.display = 'block'

      this.$http.post('/ticket', this.composeData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            this.ticketData = resp.data
            const status = true

            const promise2 = new Promise(resolve => {
              if (this.files.length !== 0) {
                for (let i = 0; i < this.files.length; i += 1) {
                  const formFile = new FormData()
                  formFile.append('ticket_id', resp.data.ticket_id)
                  formFile.append('attachment', this.files[i])

                  this.$http.post('/ticket/attach', formFile, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
                    },
                  })
                    .then(fresp => {
                      if (fresp.data.success === true) {
                        resolve(true)
                      } else {
                        document.getElementById('loading-bg').style.display = 'none'

                        this.alert = true
                        this.alertDetail = {
                          variant: 'danger',
                          title: 'Error Found',
                          text: (fresp.response.data.errors !== '') ? fresp.response.data.errors : '',
                        }
                        resolve(false)
                      }
                    })
                    .catch(error => {
                      document.getElementById('loading-bg').style.display = 'none'

                      this.alert = true
                      this.alertDetail = {
                        variant: 'danger',
                        title: 'Error Found',
                        text: (error.response.data.errors !== '') ? error.response.data.errors : error,
                      }
                      resolve(false)
                    })
                }
              } else {
                resolve(true)
              }
            })

            Promise.all([promise2, status]).then(values => {
              if (values[0] && values[1]) {
                document.getElementById('loading-bg').style.display = 'none'
                this.alert = false
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: 'New Ticket Created',
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                  },
                })
                if (this.ticketData.status_id !== 6) {
                  this.$router.push({ name: 'tickets-view', params: { id: this.ticketData.ticket_id } })
                }
                this.discardEmail()
              }
            })
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating Ticket',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.message !== '') ? error.response.data.message : error,
            errors: (error.response.data.errors !== '') ? error.response.data.errors : error,
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
form ::v-deep {

  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
