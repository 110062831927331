<template>
  <b-media-body>
    <div class="mail-details">
      <div class="mail-items">
        <h5 class="mb-25">
          <b-badge
            v-if="ticket.sla"
            v-b-tooltip.hover.top="(ticket.sla.hours < 3) ? 'SLA Achieved' : 'SLA Not Achieved'"
            :variant="(ticket.sla.hours < 3) ? 'light-success' : 'light-danger'"
            class="mr-25"
          >
            <feather-icon
              icon="MessageCircleIcon"
              size="12"
            />
          </b-badge>
          <b-badge
            v-if="ticket.status === 'Closed'"
            :variant="(ticket.closediff.hours < ticket.closediff.sla) ? 'light-info' : 'light-danger'"
            class="mr-50"
          >
            <feather-icon
              icon="CheckCircleIcon"
              size="12"
            />
            <template v-if="ticket.closediff.diff.days !== 1">
              {{ ticket.closediff.diff.days }}D
            </template>
            <template v-else>
              {{ ticket.closediff.diff.hours }}H {{ ticket.closediff.diff.minutes }}M
            </template>
          </b-badge>
          <b-badge
            v-else
            variant="light-info"
            class="mr-50"
          >
            <feather-icon
              icon="ClockIcon"
              size="12"
            />
            {{ ticket.diff.days }}D
          </b-badge>
          {{ ticket.author }}
          <small>from {{ ticket.company_name }}</small>
          <span class="mail-date ml-50">
            <feather-icon
              icon="DownloadIcon"
              size="12"
            />
            {{ formatDateToMonthShort(ticket.created_at, { hour: 'numeric', minute: 'numeric', }) }}</span>
          <span class="mail-date ml-50">
            <feather-icon
              icon="ActivityIcon"
              size="12"
            />
            {{ formatDateToMonthShort(ticket.updated_at, { hour: 'numeric', minute: 'numeric', }) }}</span>
        </h5>
        <p class="mb-50">
          <b-badge
            v-if="ticket.status !== 'Closed' && ticket.lastreply !== null && ticket.lastreply.author_type === 'author'"
            variant="primary"
            class="mr-50"
          >
            <feather-icon
              icon="MailIcon"
              size="12"
            /> New
          </b-badge>
          <b-badge
            v-if="ticket.agent"
            variant="light-primary"
          >
            <feather-icon
              icon="UserIcon"
              size="12"
            />
            {{ ticket.agent }}
          </b-badge>
        </p>
        <span class="text-truncate">{{ ticket.subject }}</span>
      </div>
      <div class="mail-meta-item">
        <b-badge
          variant="light-info"
          class="mr-25"
        >
          {{ ticket.channel }}
        </b-badge>
        <b-badge
          :variant="resolveLabelColor(ticket.status)"
          class="mr-25"
        >
          {{ ticket.status }}
        </b-badge>
        <b-badge
          :variant="resolvePrioColor(ticket.priority)"
        >
          {{ ticket.priority }}
        </b-badge>
        <b-dropdown
          v-if="ticket.status === 'New Ticket'"
          variant="link"
          boundary="window"
          no-caret
          size="sm"
          right
        >
          <template #button-content>
            <feather-icon
              icon="TrashIcon"
              size="12"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            class="small"
            @click.stop="moveSelectedEmailsToFolder(ticket, 'spam')"
          >
            <feather-icon
              icon="InfoIcon"
              size="12"
            />
            <span class="align-middle ml-50">Spam</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="ticket.channel === 'Email' && ticket.status === 'New Ticket'"
            class="small"
            @click.stop="moveSelectedEmailsToFolder(ticket, 'delete')"
          >
            <feather-icon
              icon="Trash2Icon"
              size="12"
            />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          v-else-if="ticket.status === 'Spam'"
          variant="link"
          boundary="window"
          no-caret
          size="sm"
          right
        >
          <template #button-content>
            <feather-icon
              icon="FolderIcon"
              size="12"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            class="small"
            @click.stop="moveSelectedEmailsToFolder(ticket, 'not-spam')"
          >
            <feather-icon
              icon="InfoIcon"
              size="12"
            />
            <span class="align-middle ml-50">Not Spam</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div class="mail-message">
      <p class="text-truncate mb-0">
        {{ filterTags(ticket.message) }}
      </p>
    </div>
  </b-media-body>
</template>

<script>
import {
  BMediaBody, BBadge, BDropdown, BDropdownItem, VBTooltip,
} from 'bootstrap-vue'
import moment from 'moment-business-time'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useEmail from './useEmail'

export default {
  components: {
    BMediaBody, BBadge, BDropdown, BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    moment(date) {
      const now = new Date()
      const today = moment(now).utc()
      const diff = today.workingDiff(date, 'days')
      // const diff = today.diff(date, 'days')
      return `${diff} Hari`
    },
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    reference: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const toast = useToast()
    const { resolveLabelColor, resolvePrioColor } = useEmail()
    return {
      toast,
      filterTags,
      formatDateToMonthShort,

      // useEmail
      resolveLabelColor,
      resolvePrioColor,
    }
  },
  created() {
    this.setupTanggal()
  },
  methods: {
    setupTanggal() {
      const liburAll = this.reference

      moment.updateLocale('en', {
        liburAll,
      })
    },
    moveSelectedEmailsToFolder(target, type) {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        ticket_id: target.ticket_id,
      }
      let targetUrl = '/ticket/accept'
      if (type === 'spam') {
        targetUrl = '/ticket/reject'
        params.status_id = 6
      } else if (type === 'delete') {
        targetUrl = '/ticket/remove'
        params.status_id = 6
      } else {
        params.status_id = 1
      }
      this.$http.post(targetUrl, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'
            this.$swal({
              icon: 'success',
              title: 'Update Completed!',
              text: 'Ticket status has been updated.',
              showConfirmButton: false,
            })
            setTimeout(() => {
              this.$emit('updateticket')
              this.$swal.close()
            }, 1000)
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error Updating Ticket status',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
